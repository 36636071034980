import { PlatformType } from './types'

/**
 * Check if the current app is running on either android or ios
 * and if it has been installed to the users home screen.
 * @returns the type of platform and wether the application is installed to the home screen
 */
export const useInstallation = () => {
  // The implementation is based on this blog post: https://thomashunter.name/posts/2021-12-11-detecting-if-pwa-twa-is-installed
  const UA = navigator.userAgent
  const IOS = UA.match(/iPhone|iPad|iPod/)
  const ANDROID = UA.match(/Android/)
  const standalone = window.matchMedia
    ? window.matchMedia('(display-mode: standalone)').matches
    : false
  const platform: PlatformType = IOS ? 'ios' : ANDROID ? 'android' : 'unknown'
  const installed = !!(standalone || (IOS && !UA.match(/Safari/)))
  return {
    platform,
    isMobile: platform === 'ios' || platform === 'android',
    installed,
  }
}

export const useGitInformation = () => ({
  commitHash: process.env.gitCommitHash ?? '',
  version: process.env.gitVersion ?? '',
  branch: process.env.gitBranch ?? '',
})
